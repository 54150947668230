import React from "react"
import LayoutCourse from "../../../layouts/course"
import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import CourseFooterNext from "../../../components/course-footer-next"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import { getCourseNavigation } from "../../../store/courses"
import InfoBox from "../../../components/info-box"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/reiseerinnerung/01-suedfrankreich"
        />
      }
      navigation={navigation}
    >
      <Seo title="Eine Reiseerinnerung wird zum Gedicht" />
      <Box>
        <Stack>
          <Columns
            alignY="center"
            alignX="space-between"
            collapse={[true, false]}
          >
            <Column>
              <Text sans>Ab 11. und 12. Klasse</Text>
            </Column>
            <Column width="content">
              <TextLink to="/kurse/reiseerinnerung/fuer-lehrer">
                Informationen für Lehrer*innen
              </TextLink>
            </Column>
          </Columns>
          <Heading as="h2" level={2}>
            Eine Reiseerinnerung wird zum Gedicht
          </Heading>
          <InfoBox title="Um was geht es?">
            In Hölderlins Gedichtwerk spielt das Reisen eine große Rolle: Beim
            Schreiben ließ er sich von der Sehnsucht nach fernen Ländern leiten
            und träumte sich hinaus in die Welt. Anders als andere Zeitgenossen,
            die ihre Werke ausschließlich vom Schreibtisch aus verfassten, hat
            Hölderlin aber auch abenteuerliche Wanderungen unternommen. Viele
            Beschreibungen gehen auf eigene Erfahrungen zurück. So auch im
            Gedicht ›Andenken‹, das sich auf Hölderlins Zeit als Hauslehrer in
            Südfrankreich zurückführen lässt und im Mittelpunkt dieses Kurses
            steht.
          </InfoBox>
          <InfoBox title="Was ist dein Ziel?">
            Beim ersten Lesen wird dir Hölderlins Gedicht ›Andenken‹
            wahrscheinlich etwas schwer verständlich vorkommen. Kein Wunder:
            Hölderlin ist einer der herausforderndsten Schriftsteller überhaupt.
            Doch im Laufe des Kurses erarbeitest du dir einen eigenen Zugang zu
            diesem Gedicht. Am Ende steht deine eigene künstlerische
            Interpretation, in der du Hölderlins Versen einen individuellen
            Sound verleihst und sie mit bewegten Bildern unterlegst.
          </InfoBox>
          <InfoBox title="Was lernst du in diesem Kurs?">
            Am Beispiel von Hölderlins Gedicht ›Andenken‹ lernst du verschiedene
            Wege und Methoden, mit denen man sich ein Gedicht erschließen kann.
            Du erforschst, wie Gedichte entstehen, aus welchem Material sie
            gemacht sind und wie es sich das Lesen auswirkt. Insbesondere geht
            es um die Frage, welchen Einfluss Hölderlins Reiseerfahrungen auf
            sein Gedicht genommen haben. Nebenbei erfährst du einiges über
            Hölderlins biografische und zeitgeschichtliche Hintergründe und
            seine Work & Travel-Jahre als junger Erwachsener.
          </InfoBox>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
